import { createApp } from "vue";
import MembershipForm from "../membership_form.vue";
import { Quasar, Notify, Dialog } from "quasar";

document.addEventListener("DOMContentLoaded", () => {
  const el = document.getElementById("app");
  const app = createApp(MembershipForm, {
    searchFields: JSON.parse(el.dataset.searchFields),
    accountFields: JSON.parse(el.dataset.accountFields),
    contactFields: JSON.parse(el.dataset.contactFields),
    familyMemberFields: JSON.parse(el.dataset.familyMemberFields),
    picklistValues: JSON.parse(el.dataset.picklistValues),
    preselectOrg: JSON.parse(el.dataset.preselectOrg || null),
    dynamicFields: JSON.parse(el.dataset.dynamicFields),
    accountField: el.dataset.accountField,
    productField: el.dataset.productField,
    addFamily: el.dataset.addFamily,
    familyProductIds: JSON.parse(el.dataset.familyProductIds || null),
    joiningFeeProductId: el.dataset.joiningFeeProductId,
    step1title: el.dataset.step1title,
    step2title: el.dataset.step2title,
    step3title: el.dataset.step3title,
    step4title: el.dataset.step4title,
    step1heading: el.dataset.step1heading,
    step2heading: el.dataset.step2heading,
    step3heading: el.dataset.step3heading,
    step4heading: el.dataset.step4heading,
    step1msg: el.dataset.step1msg,
    step2msg: el.dataset.step2msg,
    step3msg: el.dataset.step3msg,
    step4msg: el.dataset.step4msg,
    selectBtnText: el.dataset.selectBtnText,
    backBtnText: el.dataset.backBtnText,
    continueBtnText: el.dataset.continueBtnText,
    familyMembersBlockTitle: el.dataset.familyMembersBlockTitle,
    addPersonBtnText: el.dataset.addPersonBtnText,
    personDetailsBlockTitle: el.dataset.personDetailsBlockTitle,
    payNowBtnText: el.dataset.payNowBtnText,
    payByInvoiceBtnText: el.dataset.payByInvoiceBtnText,
    submitBtnText: el.dataset.submitBtnText,
    selectedOrganizationText: el.dataset.selectedOrganizationText,
    selectedProductText: el.dataset.selectedProductText,
    totalText: el.dataset.totalText,
    joiningFeeText: el.dataset.joiningFeeText,
    fieldRequiredText: el.dataset.fieldRequiredText,
    minLengthText: el.dataset.minLengthText,
    familyMemberMaxCount: el.dataset.familyMemberMaxCount,
    allowPayByInvoice: el.dataset.allowPayByInvoice,
    allowPayNow: JSON.parse(el.dataset.allowPayNow),
    paymentService: el.dataset.paymentService,
    loggedInContact: JSON.parse(el.dataset.loggedInContact || null),
    parallelProductIds: JSON.parse(el.dataset.parallelProductIds || null),
    usernameText: el.dataset.usernameText,
    passwordText: el.dataset.passwordText,
    loginBtnText: el.dataset.loginBtnText,
    logoutBtnText: el.dataset.logoutBtnText,
    allowLogin: el.dataset.allowLogin === "true",
    currency: el.dataset.currency,
  });
  app.use(Quasar, { plugins: { Notify, Dialog } });
  app.mount("#app");
});
