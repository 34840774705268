<template>
  <div class="q-pa-md q-mb-xl">
    <q-stepper
      v-model="step"
      ref="stepperRef"
      color="primary"
      animated
      header-nav
    >
      <q-step
        :name="1"
        prefix="1"
        :title="step1title"
        :done="step > 1"
        :header-nav="step > 1"
      >
        <div class="text-center">
          <div class="text-h4 q-my-lg">
            {{ step1heading }}
          </div>
          <p v-html="step1msg" />
        </div>

        <template v-if="searchFields.length">
          <q-tabs
            v-model="tab"
            dense
            class="text-grey"
            active-color="primary"
          >
            <q-tab
              v-for="fld in searchFields"
              :name="fld.name"
              :label="fld.label"
            />
          </q-tabs>

          <q-separator />

          <q-tab-panels
            v-model="tab"
            animated
          >
            <q-tab-panel
              v-for="fld in searchFields"
              :name="fld.name"
            >
              <q-select
                v-if="fld.type === 'picklist' || fld.type === 'multipicklist'"
                v-model="searchTerm"
                :options="picklistValues[fld.picklist_id]"
                :multiple="fld.type === 'multipicklist'"
                emit-value
                map-options
                filled
              />
              <q-input
                v-else
                v-model="searchTerm"
                debounce="500"
                filled
              >
                <template v-slot:prepend>
                  <q-icon name="search" />
                </template>
              </q-input>
            </q-tab-panel>
          </q-tab-panels>
        </template>

        <q-list>
          <q-item
            v-for="organization in organizations"
            :key="organization.crmid"
            clickable
            @click="selectOrganization(organization)"
          >
            <q-item-section
              v-if="organization.logo_url"
              avatar
            >
              <q-avatar square>
                <img :src="organization.logo_url" />
              </q-avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ organization.name }}</q-item-label>
              <q-item-label
                v-for="fld in accountFields"
                caption
              >
                <a
                  v-if="fld.type == 'url'"
                  :href="`https://${organization[fld.name]}`"
                  target="_blank"
                  @click.stop
                  >{{ organization[fld.name] }}</a
                >
                <template v-else>
                  {{ organization[fld.name] }}
                </template>
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-btn
                color="primary"
                :label="selectBtnText"
                unelevated
                rounded
                no-caps
                @click="selectOrganization(organization)"
              />
            </q-item-section>
          </q-item>
        </q-list>
      </q-step>

      <q-step
        :name="2"
        prefix="2"
        :title="step2title"
        :done="step > 2"
        :header-nav="step > 2"
      >
        <div class="text-center">
          <div class="text-h4 q-my-lg">{{ step2heading }}</div>
          <p>
            {{ selectedOrganizationText }}:
            <strong>{{ selectedOrganization.name }}</strong>
          </p>
          <p v-html="step2msg" />
          <q-banner
            v-if="joiningFeeProductPrice"
            rounded
            class="bg-orange-1"
          >
            <span v-html="joiningFeeText"></span>
            {{ formatCurrency(joiningFeeProductPrice.attributes.price) }}.
          </q-banner>
        </div>

        <q-list>
          <q-item
            v-for="pp in computedProductPrices"
            :key="pp.product.attributes.crmid"
            clickable
            @click="selectProduct(pp)"
          >
            <q-item-section>
              <q-item-label>
                {{ pp.product.attributes.productname }}
              </q-item-label>
              <q-item-label caption>
                {{ pp.product.attributes.description }}
              </q-item-label>
              <q-item-label>
                {{ formatCurrency(pp.attributes.price) }}
                <template v-if="joiningFeeProductPrice">
                  +
                  {{ formatCurrency(joiningFeeProductPrice.attributes.price) }}
                </template>
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-btn
                color="primary"
                :label="selectBtnText"
                unelevated
                rounded
                no-caps
                @click="selectProduct(pp)"
              />
            </q-item-section>
          </q-item>
        </q-list>
      </q-step>

      <q-step
        :name="3"
        prefix="3"
        :title="step3title"
        :done="step > 3"
        :header-nav="step > 3"
      >
        <div class="text-center">
          <div class="text-h4 q-my-lg">{{ step3heading }}</div>
          <p>
            {{ selectedOrganizationText }}:
            <strong>{{ selectedOrganization.name }}</strong
            >. {{ selectedProductText }}:
            <strong>{{
              selectedProduct.product.attributes.productname
            }}</strong>
          </p>
          <p v-html="step3msg" />

          <q-btn
            v-if="loginAvailable && !loginRequired"
            unelevated
            color="primary"
            rounded
            no-caps
            :label="loginBtnText"
            class="q-mb-md"
            @click="loginDialog = true"
          />
        </div>

        <div
          v-if="loginRequired"
          class="q-mt-lg q-mx-auto text-center overflow-auto"
          style="max-width: 400px"
        >
          <login-form
            v-model:username="username"
            v-model:password="password"
            :username-text="usernameText"
            :password-text="passwordText"
            :login-btn-text="loginBtnText"
            :loading="submittingLogin"
            :loginError="loginError"
            @submit="login"
          />
        </div>

        <q-form
          v-else
          greedy
          class="overflow-auto"
          @submit="$refs.stepperRef.next()"
        >
          <div class="row q-col-gutter-md">
            <div
              v-for="column in fieldBlockColumns"
              :class="[
                'col-12',
                { 'col-md-6': fieldBlockColumns.length > 1 },
                'column',
                'q-gutter-y-md',
              ]"
            >
              <q-card
                v-for="(flds, block) in column"
                flat
                bordered
              >
                <q-card-section
                  v-if="block !== 'null'"
                  class="q-pb-none"
                >
                  <div class="text-h6">{{ block }}</div>
                </q-card-section>
                <q-card-section>
                  <div class="column q-gutter-y-md">
                    <component
                      v-for="fld in flds"
                      :key="fld.name"
                      :is="formControl(fld)"
                      v-bind="formControlProps(fld, instance)"
                      :stack-label="loggedIn"
                      :edit="!loggedIn"
                      :disable="loggedIn"
                    />
                  </div>
                </q-card-section>
              </q-card>
            </div>

            <div
              v-if="isFamilyMembership"
              class="col-12 col-md-6 column q-gutter-y-md"
            >
              <q-card
                flat
                bordered
              >
                <q-card-section class="q-pb-none">
                  <div class="text-h6">{{ familyMembersBlockTitle }}</div>
                </q-card-section>
                <template v-for="(instance, index) in familyMembers">
                  <q-card-section>
                    <q-card
                      flat
                      bordered
                    >
                      <q-card-section class="q-pb-none row items-center">
                        <div class="text-subtitle1">
                          {{ personDetailsBlockTitle }}
                        </div>
                        <q-space />
                        <q-btn
                          icon="close"
                          flat
                          round
                          dense
                          @click="removeFamilyMember(index)"
                        />
                      </q-card-section>
                      <q-card-section>
                        <div class="column q-gutter-y-md">
                          <component
                            v-for="fld in familyMemberFields"
                            :is="formControl(fld)"
                            v-bind="formControlProps(fld, instance)"
                            edit
                          />
                        </div>
                      </q-card-section>
                    </q-card>
                  </q-card-section>
                </template>
                <q-card-section>
                  <q-btn
                    v-if="
                      !familyMemberMaxCount ||
                      familyMembers.length < familyMemberMaxCount
                    "
                    color="primary"
                    :label="addPersonBtnText"
                    icon="add"
                    unelevated
                    rounded
                    no-caps
                    @click="addFamilyMember"
                  />
                </q-card-section>
              </q-card>
            </div>
          </div>
          <q-stepper-navigation>
            <q-btn
              unelevated
              rounded
              no-caps
              type="submit"
              color="primary"
              :label="continueBtnText"
              class="full-width"
              size="lg"
            />
            <q-btn
              v-if="loggedIn"
              flat
              rounded
              no-caps
              color="primary"
              :label="logoutBtnText"
              class="full-width q-mt-sm"
              @click="logout"
            />
          </q-stepper-navigation>
        </q-form>
      </q-step>

      <q-step
        :name="4"
        prefix="4"
        :title="step4title"
        :header-nav="step > 4"
      >
        <div class="text-center">
          <div class="text-h4 q-my-lg">{{ step4heading }}</div>
          <p v-html="step4msg" />
        </div>

        <div class="row q-col-gutter-md q-mb-lg">
          <div class="col-12 col-md-6 column q-gutter-y-md">
            <q-card
              v-for="(flds, block) in fieldBlocks"
              flat
              bordered
            >
              <q-card-section
                v-if="block !== 'null'"
                class="q-pb-none"
              >
                <div class="text-h6">{{ block }}</div>
              </q-card-section>
              <q-card-section>
                <div class="column q-gutter-y-md">
                  <component
                    v-for="fld in flds"
                    :is="formControl(fld)"
                    v-bind="formControlProps(fld, instance)"
                    readonly
                    stack-label
                  />
                </div>
              </q-card-section>
            </q-card>

            <q-card
              v-if="isFamilyMembership && familyMembers.length"
              flat
              bordered
            >
              <q-card-section class="q-pb-none">
                <div class="text-h6">{{ familyMembersBlockTitle }}</div>
              </q-card-section>
              <template v-for="instance in familyMembers">
                <q-card-section>
                  <q-card
                    flat
                    bordered
                  >
                    <q-card-section>
                      <div class="column q-gutter-y-md">
                        <component
                          v-for="fld in familyMemberFields"
                          :is="formControl(fld)"
                          v-bind="formControlProps(fld, instance)"
                          readonly
                          stack-label
                        />
                      </div>
                    </q-card-section>
                  </q-card>
                </q-card-section>
              </template>
            </q-card>
          </div>
          <div class="col-12 col-md-6">
            <q-card
              flat
              bordered
            >
              <q-card-section class="q-pb-none">
                <div class="text-h6">{{ step2title }}</div>
              </q-card-section>
              <q-card-section>
                <div class="text-subtitle1 text-uppercase">
                  {{ selectedOrganization.name }}
                </div>
                <div class="row q-col-gutter-x-md">
                  <div class="col-9">
                    {{ selectedProduct.product.attributes.productname }}
                  </div>
                  <div class="col-3">
                    {{ formatCurrency(selectedProduct.attributes.price) }}
                  </div>
                </div>
                <div
                  v-if="joiningFeeProductPrice"
                  class="row q-col-gutter-x-md"
                >
                  <div class="col-9">
                    {{ joiningFeeProductPrice.product.attributes.productname }}
                  </div>
                  <div class="col-3">
                    {{
                      formatCurrency(joiningFeeProductPrice.attributes.price)
                    }}
                  </div>
                </div>

                <q-separator class="q-my-xs" />

                <div class="row q-col-gutter-x-md">
                  <div class="col-9">{{ totalText }}</div>
                  <div class="col-3">
                    <strong>{{ formatCurrency(totalPrice) }}</strong>
                  </div>
                </div>
              </q-card-section>
            </q-card>
          </div>
        </div>

        <q-stepper-navigation class="text-center">
          <template v-if="totalPrice > 0">
            <div class="q-mb-sm">
              <q-btn
                v-if="allowPayNow"
                color="primary"
                :label="payNowBtnText"
                :disable="submitting"
                size="lg"
                unelevated
                rounded
                no-caps
                class="full-width"
                @click="submit(paymentService)"
              />
            </div>
            <div>
              <q-btn
                v-if="allowPayByInvoice"
                color="primary"
                :label="payByInvoiceBtnText"
                :disable="submitting"
                size="lg"
                unelevated
                rounded
                no-caps
                class="full-width"
                @click="submit('Invoice')"
              />
            </div>
          </template>
          <q-btn
            v-else
            color="primary"
            :label="submitBtnText"
            :disable="submitting"
            size="lg"
            unelevated
            rounded
            no-caps
            class="full-width"
            @click="submit()"
          />
        </q-stepper-navigation>
      </q-step>

      <template v-slot:navigation>
        <q-stepper-navigation>
          <q-btn
            v-if="step > 1"
            flat
            color="primary"
            rounded
            no-caps
            @click="$refs.stepperRef.previous()"
            :label="backBtnText"
            :disable="submitting"
            class="full-width"
          />
        </q-stepper-navigation>
      </template>
    </q-stepper>
  </div>

  <q-dialog v-model="loginDialog">
    <q-card style="width: 400px">
      <q-card-section class="text-center">
        <login-form
          v-model:username="username"
          v-model:password="password"
          :username-text="usernameText"
          :password-text="passwordText"
          :login-btn-text="loginBtnText"
          :loading="submittingLogin"
          :loginError="loginError"
          @submit="login"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, computed, watch, watchEffect, onMounted } from "vue";
import axios from "axios";
import { useQuasar } from "quasar";
import { useFormControl } from "./composables/use_form_control";
import { useContactFields } from "./composables/use_contact_fields";
import { useCurrentUser } from "./composables/use_current_user";
import { useTokens } from "./composables/use_tokens";
import { useCurrency } from "./composables/use_currency";
import LoginForm from "./components/membership_login_form.vue";

function present(value) {
  if (Array.isArray(value)) {
    return value.length > 0;
  }
  return value !== null && value !== undefined && value !== "";
}

export default {
  components: { LoginForm },
  props: [
    "searchFields",
    "accountFields",
    "contactFields",
    "familyMemberFields",
    "picklistValues",
    "preselectOrg",
    "dynamicFields",
    "accountField",
    "productField",
    "addFamily",
    "familyProductIds",
    "step1title",
    "step2title",
    "step3title",
    "step4title",
    "step1heading",
    "step2heading",
    "step3heading",
    "step4heading",
    "step1msg",
    "step2msg",
    "step3msg",
    "step4msg",
    "selectBtnText",
    "backBtnText",
    "continueBtnText",
    "familyMembersBlockTitle",
    "addPersonBtnText",
    "personDetailsBlockTitle",
    "payNowBtnText",
    "payByInvoiceBtnText",
    "submitBtnText",
    "joiningFeeProductId",
    "selectedOrganizationText",
    "selectedProductText",
    "totalText",
    "joiningFeeText",
    "fieldRequiredText",
    "minLengthText",
    "familyMemberMaxCount",
    "allowPayByInvoice",
    "allowPayNow",
    "loggedInContact",
    "parallelProductIds",
    "usernameText",
    "passwordText",
    "loginBtnText",
    "logoutBtnText",
    "allowLogin",
    "currency",
    "paymentService",
  ],

  setup(props) {
    const searchTerm =
      props.searchFields[0]?.type === "multipicklist" ? ref([]) : ref("");
    const selectedOrganization = ref(props.preselectOrg);
    const selectedProduct = ref(null);
    const stepperRef = ref(null);
    const tab = ref(props.searchFields[0]?.name);
    const organizations = ref([]);
    const productPrices = ref([]);
    const step = props.preselectOrg ? ref(2) : ref(1);
    const $q = useQuasar();
    const familyMembers = ref([]);
    const { formControl, formControlProps } = useFormControl(
      props.picklistValues,
      props.fieldRequiredText,
      props.minLengthText
    );

    watchEffect(async () => {
      if (present(searchTerm.value)) {
        const { data } = await axios.get("/public/accounts.json", {
          params: {
            filter_by: tab.value,
            filter_value: searchTerm.value,
          },
        });
        organizations.value = data;
      } else {
        organizations.value = [];
      }
    });

    watch(
      selectedOrganization,
      async (org) => {
        if (org) {
          const { data } = await axios.get("/public/products.json", {
            params: {
              organization_id: org.crmid,
            },
          });
          productPrices.value = data;
        }
      },
      { immediate: true }
    );

    watch(step, (newStep) => {
      if (newStep === 1) {
        history.pushState(null, null, "/public/membership/new");
      }
    });

    watch(tab, (newTab) => {
      const searchField = props.searchFields.find((fld) => fld.name === newTab);
      searchTerm.value = searchField?.type === "multipicklist" ? [] : "";
    });

    onMounted(async () => {
      if (props.searchFields.length === 0) {
        const { data } = await axios.get("/public/accounts.json");
        organizations.value = data;
      }
    });

    const computedInstance = computed(() => {
      const dup = { ...instance.value };
      if (props.productField) {
        dup[props.productField] =
          selectedProduct.value.product.attributes.crmid;
      }
      if (props.accountField) {
        dup[props.accountField] = selectedOrganization.value.crmid;
      }
      return dup;
    });

    const computedProductPrices = computed(() =>
      productPrices.value.filter(
        (pp) => pp.attributes.product_id !== Number(props.joiningFeeProductId)
      )
    );

    const joiningFeeProductPrice = computed(() =>
      productPrices.value.find(
        (pp) => pp.attributes.product_id === Number(props.joiningFeeProductId)
      )
    );

    const { fieldBlockColumns, fieldBlocks } = useContactFields(
      props.contactFields,
      props.dynamicFields,
      computedInstance
    );

    const isFamilyMembership = computed(
      () =>
        props.addFamily ||
        (props.familyProductIds || []).includes(
          selectedProduct.value?.product.attributes.crmid
        )
    );

    function selectOrganization(organization) {
      selectedOrganization.value = organization;
      stepperRef.value.next();
      history.pushState(
        null,
        null,
        `/public/membership/new/${organization.crmid}`
      );
    }

    function selectProduct(productPrice) {
      selectedProduct.value = productPrice;
      stepperRef.value.next();
    }

    function addFamilyMember() {
      familyMembers.value.push({});
    }

    function removeFamilyMember(index) {
      familyMembers.value.splice(index, 1);
    }

    const { formatCurrency } = useCurrency(props.currency);

    const totalPrice = computed(
      () =>
        Number(selectedProduct.value?.attributes.price || null) +
        Number(joiningFeeProductPrice.value?.attributes.price || null)
    );

    const { csrfToken, recaptchaSiteKey } = useTokens();

    const recaptchaAction = "publicmembershipsubmit";

    const submitting = ref(false);

    function submit(paymentMethod) {
      submitting.value = true;
      grecaptcha.ready(async () => {
        const token = await grecaptcha.execute(recaptchaSiteKey, {
          action: recaptchaAction,
        });
        try {
          const { data } = await axios.post(
            "/public/membership.json",
            {
              pay_type: paymentMethod,
              instance: computedInstance.value,
              family_members: isFamilyMembership.value
                ? familyMembers.value
                : [],
              "g-recaptcha-response-data": {
                [recaptchaAction]: token,
              },
            },
            {
              headers: {
                "X-CSRF-Token": csrfToken,
              },
            }
          );
          window.location = data.location;
        } catch (e) {
          if (axios.isAxiosError(e) && e.response) {
            $q.dialog({
              html: true,
              message: e.response.data.error,
            });
          } else {
            throw e;
          }
        } finally {
          submitting.value = false;
        }
      });
    }

    const isParallelMembership = computed(() =>
      (props.parallelProductIds || []).includes(
        selectedProduct.value?.product.attributes.crmid
      )
    );

    const {
      instance,
      loggedIn,
      loginAvailable,
      login,
      currentUser,
      username,
      password,
      logout,
      submittingLogin,
      loginError,
      loginDialog,
    } = useCurrentUser(props.loggedInContact, props.allowLogin);

    const loginRequired = computed(
      () => isParallelMembership.value && currentUser.value === null
    );

    return {
      step,
      tab,
      searchTerm,
      stepperRef,
      selectOrganization,
      selectedOrganization,
      computedProductPrices,
      productPrices,
      joiningFeeProductPrice,
      selectProduct,
      selectedProduct,
      instance,
      fieldBlocks,
      organizations,
      fieldBlockColumns,
      submit,
      computedInstance,
      familyMembers,
      addFamilyMember,
      removeFamilyMember,
      isFamilyMembership,
      formControl,
      formControlProps,
      formatCurrency,
      totalPrice,
      submitting,
      username,
      password,
      login,
      logout,
      submittingLogin,
      loginError,
      loggedIn,
      loginRequired,
      loginDialog,
      loginAvailable,
    };
  },
};
</script>
